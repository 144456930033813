import { get, camelCase } from "lodash";
import { BillingContactListResponseBody } from "@gocardless/api/dashboard/billing-contact";
import { DeveloperContactListResponseBody } from "@gocardless/api/dashboard/developer-contact";

export interface Contact {
  id: string;
  email: string;
  familyName: string;
  givenName: string;
  phoneNumber: string;
  locale: string;
  createdAt: string;
}

export const parseBillingContact = (
  list:
    | BillingContactListResponseBody
    | DeveloperContactListResponseBody
    | undefined
) => parseContact("billing", list);

export const parseContact = (
  prefix: string,
  list:
    | BillingContactListResponseBody
    | DeveloperContactListResponseBody
    | undefined
) => {
  const defaultContact: Contact = {
    id: "",
    email: "",
    familyName: "",
    givenName: "",
    phoneNumber: "",
    locale: "",
    createdAt: "",
  };
  const contact = get(list, `${prefix}_contacts[0]`, defaultContact);
  return Object.fromEntries(
    Object.entries(contact).map(([key, value]) => [camelCase(key), value])
  ) as Contact;
};
